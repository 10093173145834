<template>
  <div class="skeleton-table">
    <v-skeleton-loader v-for="index in 7" :key="`sk-list-${index}`" type="table-cell@6"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "SkeletonTable"
}
</script>