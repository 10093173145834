<template>
  <div>
    <div class="text-h6">
      <v-row class="justify-content-between">
        <v-col>
          <div class="col-6 mt-1">
            Profile
          </div>
        </v-col>
        <v-col class="col col-6 d-flex justify-content-end flex-end mt-3">
          <v-btn @click.stop="openEditProfile('edit', getAuthUser.id)" color="primary" outlined class="mr-2">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click.stop="openChangePassword('edit', getAuthUser.id)" color="primary" outlined>
            Change Password
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-divider class="mt-6 mb-4" color="default"></v-divider>
    <snackbar ref="snackbar" :message="snackbarMessage"></snackbar>
    <v-row class="mt-1 profile-height">
      <v-col cols="12">
        <v-row class="w-100">
          <v-col cols="2" class="pr-0">
            Name :
          </v-col>
          <v-col cols="4" class="">
            <p :key="getAuthUser.id">{{ getAuthUser.name }}</p>
          </v-col>
          <v-col cols="2" class="pr-0  pl-0">
            Email :
          </v-col>
          <v-col cols="4" class="">
            <p :key="getAuthUser.id">{{ getAuthUser.email }}</p>
          </v-col>
        </v-row>
        <v-row class="w-100">
          <v-col cols="2" class="pr-0">
            Phone :
          </v-col>
          <v-col cols="4" class="">
            <p :key="getAuthUser.id">{{ getAuthUser.phone }}</p>
          </v-col>
          <v-col cols="2" class="pr-0 pl-0">
            Job Title :
          </v-col>
          <v-col cols="4" class="">
            <p :key="getAuthUser.id" v-if="getAuthUser.job_title !== null && getAuthUser.job_title !== ''">{{getAuthUser.job_title}}</p>
            <p :key="getAuthUser.id" v-else><span class="text--secondary">Not Set</span></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-divider class="my-4" color="default"></v-divider>
    <edit-profile
        ref="EditProfile"
        @close-form="closeEditProfile"
        @message="message"
        :formType="formType"
        :dialogState="editProfileState"
    ></edit-profile>
    <change-password
        ref="ChangePassword"
        @close-form="closeChangePassword"
        @message="message"
        :formType="formType"
        :dialogState="changePasswordState"
    ></change-password>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Snackbar from "@/components/Snackbar";
import SkeletonTable from "@/components/skeletons/SkeletonTable";

const EditProfile = () => import(/* webpackChunkName: "EditProfile" */'./EditProfile');
const ChangePassword = () => import(/* webpackChunkName: "user-form" */'./ChangePassword');

export default {
  name: "Profile",
  components: {SkeletonTable, EditProfile, Snackbar, ChangePassword},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    formType: 'edit',
    editProfileState: false,
    changePasswordState: false,
    snackbarMessage: '',
  }),
  computed: {
    ...mapGetters(['getAuthUser', 'getUsersLoadStatus'])
  },
  methods: {
    openEditProfile(state, id = null) {
      this.formType = state;
      if (state === 'edit') this.setUserById(id);
      if (this.$refs.EditProfile) this.$refs.EditProfile.initForm(state);
      this.editProfileState = true;
    },
    openChangePassword(state, id = null) {
      this.formType = state;
      if (state === 'edit') this.setUserById(id);
      if (this.$refs.changePassword) this.$refs.changePassword.initForm(state);
      this.changePasswordState = true;
    },
    closeChangePassword() {
      this.changePasswordState = false;
    },
    closeEditProfile() {
      this.editProfileState = false;
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    message(msg, type) {
      this.snackbarMessage = msg;
      this.$refs.snackbar.type = type;
      this.$refs.snackbar.show = true;
    },

    ...mapMutations(['setUserById']),
    ...mapActions(['deleteUser'])
  }
}
</script>

<style scoped>
.flex-end {
  justify-content: flex-end;
}

.profile-height {
  /*min-height: 60vh;*/
}
</style>